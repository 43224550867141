/* Reset overrides */
code,
kbd,
samp,
pre,
.ui.code.input input,
.text-mono {
	font-family: Consolas, 'Courier New', Courier, 'Lucida Console', monospace;
}

/* Link styling */
a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a) {
	text-decoration: none;
	text-decoration-color: transparent;
	-webkit-transition: color 0.2s ease, text-decoration-color 0.2s ease;
	transition: color 0.2s ease, text-decoration-color 0.2s ease;
}
a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover {
	text-decoration: underline;
	text-decoration-color: currentColor;
}
th a, th a:hover {
	color: inherit;
}

/* Inline code style */
code:not([class*="language-"]),
kbd {
	font-size: 87.5%;
	background-color: rgba(0, 0, 0, .04);
	border-radius: 3px;
	white-space: pre;
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}
kbd {
	background-color: rgba(0, 0, 0, .8);
	color: rgba(255, 255, 255, .8);
	letter-spacing: .05em;
	padding: 0 .25em;
}
code:not([class*="language-"])::before,
kbd::before {
	letter-spacing: -0.2em;
	content: '\00a0';
}
code:not([class*="language-"])::after,
kbd::after {
	letter-spacing: -0.2em;
	content: '\00a0';
}

/* Canvas image centering fix */
canvas.ui.image {
	display: block;
}

/* React root */
#kit-app-root {
	display: block;
	width: 100%;
	height: 100%;
}

/* Utilities */
.w-0 {width: 0!important;}
.w-32 {width: 32px!important;}
.w-50 {width: 50px!important;}
.w-100 {width: 100px!important;}
.w-150 {width: 150px!important;}
.w-200 {width: 200px!important;}
.w-250 {width: 250px!important;}
.w-300 {width: 300px!important;}
.w-350 {width: 350px!important;}
.w-400 {width: 400px!important;}
.w-450 {width: 450px!important;}
.w-500 {width: 500px!important;}
.w-550 {width: 550px!important;}
.w-600 {width: 600px!important;}
.w-650 {width: 650px!important;}
.h-32 {height: 32px!important;}
.h-50 {height: 50px!important;}
.h-100 {height: 100px!important;}
.h-150 {height: 150px!important;}
.h-200 {height: 200px!important;}
.h-250 {height: 250px!important;}
.h-300 {height: 300px!important;}
.h-350 {height: 350px!important;}
.h-400 {height: 400px!important;}
.h-450 {height: 450px!important;}
.h-500 {height: 500px!important;}
.h-550 {height: 550px!important;}
.h-600 {height: 600px!important;}
.h-650 {height: 650px!important;}
.d-none {display: none;}
.d-block {display: block;}
.d-inline-block {display: inline-block;}
.text-unselectable {
	user-select: none;
	speak: none;
}
.text-secondary { color: rgba(0,0,0,.6); }
.text-secondary.inverted { color: hsla(0,0%,100%,.6); }
.text-upper { text-transform: uppercase!important; }
.text-lower { text-transform: lowercase!important; }
.text-ha-left { text-align: left!important; }
.text-ha-center { text-align: center!important; }
.text-ha-right { text-align: right!important; }
.text-bold { font-weight: 600; }
.copy-textarea {
	width: 1px!important;
	height: 1px!important;
	z-index: -1;
	position: fixed;
	top: 100%;
	left: 100%;
}

/* Flex utilities */
.kit-ha {
	display: flex;
	flex-flow: row wrap;
}
.kit-va {
	display: flex;
	flex-flow: column wrap;
}
.kit-ha-left {
	align-items: flex-start;
}
.kit-ha-center {
	align-items: center;
}
.kit-ha-right {
	align-items: flex-end;
}
.kit-va-top {
	justify-content: flex-start;
}
.kit-va-middle {
	justify-content: center;
}
.kit-va-bottom {
	justify-content: flex-end;
}
.kit-va-expand {
	min-height: 100%;
	flex: 1 0 auto!important;
}
.kit-ha-expand {
	min-width: 100%;
	flex: 1 0 auto!important;
}
.kit-va.kit-va-middle.kit-va-expand {
	padding: 1rem 0;
}

/* Link styles */
.ui.image.label .detail a {
	color: rgba(0, 0, 0, .6);
}
.ui.image.label .detail a:hover,
.ui.image.label .detail a:focus {
	color: rgba(0, 0, 0, .75);
}
.ui.image.label .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before {
	background-color: rgba(0, 0, 0, .6);
}
.ui.image.label .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before {
	background-color: rgba(0, 0, 0, .75);
}

.ui.image.label.red .detail a,
.ui.image.label.orange .detail a,
.ui.image.label.yellow .detail a,
.ui.image.label.olive .detail a,
.ui.image.label.green .detail a,
.ui.image.label.teal .detail a,
.ui.image.label.blue .detail a,
.ui.image.label.violet .detail a,
.ui.image.label.purple .detail a,
.ui.image.label.pink .detail a,
.ui.image.label.brown .detail a,
.ui.image.label.grey .detail a,
.ui.image.label.black .detail a {
	color: hsla(0,0%,100%,.75);
}
.ui.image.label.red .detail a:hover,
.ui.image.label.red .detail a:focus,
.ui.image.label.orange .detail a:hover,
.ui.image.label.orange .detail a:focus,
.ui.image.label.yellow .detail a:hover,
.ui.image.label.yellow .detail a:focus,
.ui.image.label.olive .detail a:hover,
.ui.image.label.olive .detail a:focus,
.ui.image.label.green .detail a:hover,
.ui.image.label.green .detail a:focus,
.ui.image.label.teal .detail a:hover,
.ui.image.label.teal .detail a:focus,
.ui.image.label.blue .detail a:hover,
.ui.image.label.blue .detail a:focus,
.ui.image.label.violet .detail a:hover,
.ui.image.label.violet .detail a:focus,
.ui.image.label.purple .detail a:hover,
.ui.image.label.purple .detail a:focus,
.ui.image.label.pink .detail a:hover,
.ui.image.label.pink .detail a:focus,
.ui.image.label.brown .detail a:hover,
.ui.image.label.brown .detail a:focus,
.ui.image.label.grey .detail a:hover,
.ui.image.label.grey .detail a:focus,
.ui.image.label.black .detail a:hover,
.ui.image.label.black .detail a:focus {
	color: hsla(0,0%,100%,.87);
}

.ui.image.label.red .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.orange .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.yellow .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.olive .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.green .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.teal .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.blue .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.violet .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.purple .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.pink .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.brown .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.grey .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before,
.ui.image.label.black .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before {
	background-color: hsla(0,0%,100%,.75);
}
.ui.image.label.red .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.orange .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.yellow .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.olive .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.green .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.teal .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.blue .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.violet .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.purple .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.pink .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.brown .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.grey .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before,
.ui.image.label.black .detail a:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before {
	background-color: hsla(0,0%,100%,.87);
}

.link-secondary {
	color: rgba(0,0,0,.4);
	transition: color .1s ease;
}
a.link-secondary:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before {
	background-color: rgba(0,0,0,.4);
}
.link-secondary.inverted {
	color: hsla(0,0%,100%,.6);
}
a.link-secondary.inverted:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a)::before {
	background-color: hsla(0,0%,100%,.6);
}
.link-secondary:hover,
.link-secondary:focus {
	color: rgba(0,0,0,.6);
}
a.link-secondary:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before {
	background-color: rgba(0,0,0,.6);
}
.link-secondary.inverted:hover,
.link-secondary.inverted:focus {
	color: hsla(0,0%,100%,.8);
}
a.link-secondary.inverted:not(.step):not(.ui):not(.content):not(.card):not(.card > .content.extra a):not(.ui.menu a):not(th a):hover::before {
	background-color: hsla(0,0%,100%,.8);
}

.link-underline {
	transition: color .1s ease, border-color .1s ease;
	border-bottom: 1px dotted #4183C4;
}
.link-underline:hover,
.link-underline:focus {
	border-bottom-style: solid;
}

.link-secondary.link-underline {
	border-bottom-color: rgba(0,0,0,.4);
}
.link-secondary.link-underline:hover,
.link-secondary.link-underline:focus {
	border-bottom-color: rgba(0,0,0,.6);
}
.link-secondary.link-underline.inverted {
	border-bottom-color: hsla(0,0%,100%,.6);
}
.link-secondary.link-underline.inverted:hover,
.link-secondary.link-underline.inverted:focus {
	border-bottom-color: hsla(0,0%,100%,.8);
}

.link-disabled {
	user-select: none;
	pointer-events: none;
}

/* Plain button */
.ui.plain.button {
	background: transparent;
	margin: 0;
	padding: .5em;
	border: none;
}
.ui.plain.button:hover {
	background: rgba(0, 0, 0, .1);
}
.ui.plain.button:active {
	background: rgba(0, 0, 0, .125);
}
.ui.plain.button:focus {
	outline: 2px solid #F2711C;
}

.ui.button[class*="very plain"] {
	background: transparent;
	margin: 0;
	padding: 0;
	font-weight: normal;
	color: inherit;
}
.ui.button[class*="very plain"]:hover,
.ui.button[class*="very plain"]:focus {
	color: inherit;
	background: transparent;
}

/* Middle aligned button */
.ui.button[class*="middle aligned"] {
	vertical-align: middle;
}

/* Navigation */
.ui.plain.button.kit-dash-navtoggle,
.ui.plain.button.kit-dash-usermenutoggle {
	position: absolute;
	top: 0.5em;
	padding: 0.7em;
	z-index: 4;
}
.kit-dash-navtoggle {
	left: 0.5em;
}
.kit-dash-navtoggle.orange { /* recommend adding: .small.compact for this style */
	left: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.kit-dash-usermenutoggle {
	right: 0.5em;
}
.kit-dash-usermenutoggle .ui.avatar.image {
	margin-top: -0.7em;
	margin-left: -0.42857142em;
	margin-bottom: -0.5em;
	margin-right: 0.5em;
	background-color: #f2711c40;
}
.kit-nav-container {
	position: relative;
	max-width: 250px;
	width: 0;
	min-height: 100%;
	will-change: width;
}
.kit-nav-container.should-animate {
	transition: width .2s ease;
}
.kit-nav-hoverdetect {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 100%;
	height: 100%;
	width: 2em;
}
.kit-nav-menu-container {
	display: block;
	height: 100%;
	overflow: hidden;
}
.kit-nav-container.visible,
.kit-nav-container.hovered {
	width: 250px;
}
.ui.menu.kit-nav {
	display: flex;
	width: 250px;
	height: 100%;
	max-height: 100vh;
	margin: 0;
}
.kit-nav-container.visible .ui.menu.kit-nav,
.kit-nav-container.becoming-hidden .ui.menu.kit-nav {
	border: none;
	border-radius: 0;
}
.kit-nav-container:not(.visible):not(.becoming-hidden) .ui.menu.kit-nav {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: none;
	height: 80vh;
	margin-top: 10vh;
}
.kit-nav-container:not(.visible):not(.becoming-hidden) .kit-nav-header .segment {
	border-top-right-radius: 0.28571429rem;
}
.kit-nav-container:not(.visible):not(.becoming-hidden) .kit-nav-copyright-item {
	border-bottom-right-radius: 0.28571429rem;
}
.kit-nav-container:not(.visible):not(.becoming-hidden) {
	position: absolute;
	z-index: 3;
}
.kit-dash-segment-container > .ui.container > div > .ui.attached.tabular.menu .item.active,
.kit-dash-segment-container > div > .ui.attached.tabular.menu .item.active,
.kit-dash-segment-container > .ui.attached.tabular.menu .item.active {
	border-radius: 0!important;
	border-top: none;
}
.kit-dash-segment-container > div > .ui.attached.tabular.menu ~ .ui.segment,
.kit-dash-segment-container > .ui.attached.tabular.menu ~ .ui.segment {
	border-radius: 0!important;
	border-left: none;
	border-right: none;
}
.kit-dash-segment-container > div > .ui.attached.tabular.menu > .item.active:first-child,
.kit-dash-segment-container > .ui.attached.tabular.menu > .item.active:first-child {
	border-left: none;
}
.kit-dash-segment-container > .ui.vertical.segment .ui.attached.tabular.menu {
	margin-top: 1em;
	margin-bottom: calc(-1em - 1px);
}
.kit-dash-segment-container > .ui.attached.message {
	border-radius: 0;
	margin: 0;
	margin-left: -1px;
	margin-top: -1px;
}
.kit-nav-content {
	display: flex;
	height: 100%;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
}
.kit-nav-content > .fill {
	flex: 0 1 100%;
	position: relative;
}
.kit-nav-content > .fill::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: rgba(34,36,38,.1);
}
.ui.menu.inverted.kit-nav > .kit-nav-content > .fill::before {
	background: hsla(0,0%,100%,.08);
}
.kit-nav-header .ui.segment {
	padding: 0.7em;
}
.kit-nav-header .kit-logo-container {
	margin: 0;
	width: auto;
}
.kit-nav-header .kit-logo {
	display: inline-block;
	vertical-align: middle;
	height: 2em;
	width: auto;
}
.kit-nav-header .kit-logo-part {
	fill: #fff;
}
.kit-nav-header .kit-logo-background {
	fill: none;
}
.kit-nav-header .segment a:hover .kit-logo-part {
	fill: rgba(255, 255, 255, .8);
}
.ui.inverted.menu .item.kit-nav-copyright-item,
.ui.inverted.menu .item.kit-nav-copyright-item > a:not(.step):not(.ui) {
	background: rgba(0,0,0,.5);
}
.ui.menu .ui.dropdown .menu > .item.red {
	color: #db2828!important;
}

/* Logo */
.kit-logo {
	display: block;
	width: 100%;
}
.kit-logo-container {
	width: 25em;
	margin: 0 auto 1.5em auto;
}
.kit-logo-part {
	fill:#F2711C;
}
.kit-logo-background {
	fill:#FFFFFF;
}
.kit-logo-title {
	color: #F2711C;
	text-align: center;
	font-size: 1.25em;
	margin-bottom: .5em;
}

/* Dashboard */
.kit-dash {
	display: flex;
	min-height: 100%;
}
.kit-dash-container {
	flex: 1 0 auto;
	max-height: 100vh;
	max-width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
.kit-dash-content {
	flex: 1 0 auto;
	max-height: calc(100% - 3.4285em);
	overflow: auto;
	position: relative;
}
.kit-dash-content:not(.kit-dash-content-fullheight) {
	/* leave some empty space at the bottom of the page */
	padding-bottom: 3rem;
	/* always show vertical scrollbar */
	overflow-y: scroll;
	overflow-x: auto;
}
.kit-dash:not(.kit-dash-constrained):not(.kit-dash-fluid) .kit-dash-content:not(.kit-dash-content-fullheight) .kit-dash-segment-container > .ui[class*="very padded"].vertical.segment {
	padding-left: calc(3em + 10px); /* offset by semantic @customScrollbarWidth */
}
.kit-dash-breadcrumb-segment .ui.breadcrumb {
	position: relative;
	z-index: 1;
}
.kit-dash-segment-container > .ui.vertical.segment,
.kit-dash-segment-container > .ui.padded.segment.vertical.segment,
.kit-dash-segment-container > .ui[class*="very padded"].vertical.segment {
	padding-left: 3em;
	padding-right: 3em;
}
.kit-dash-segment-container > .ui.vertical.segment:not(.secondary):not(.tertiary):not(.basic) {
	background: #fff;
}
.kit-dash-segment-container > .ui.vertical.segment:last-child {
	border-bottom: 1px solid rgba(34,36,38,.15);
}
.kit-dash-constrained .kit-dash-constrainer {
	width: auto;
	height: 100%;
	max-width: 960px;
	position: relative;
}
.kit-dash-constrained .kit-dash-content:not(.kit-dash-content-fullheight) .kit-dash-constrainer {
	min-height: 100%;
	height: auto;
}
.kit-dash-constrainer.kit-dash-segment-container > .ui.vertical.segment,
.kit-dash-constrainer .kit-dash-segment-container > .ui.vertical.segment {
	border-right: 1px solid rgba(34,36,38,.15);
}
.kit-dash-constrainer.kit-dash-segment-container.kit-dash-scroll-content > .ui.vertical.segment,
.kit-dash-constrainer .kit-dash-segment-container.kit-dash-scroll-content > .ui.vertical.segment {
	border-right: none;
}
.kit-dash.kit-dash-constrained .kit-dash-constrainer.kit-dash-segment-container > .ui.vertical.segment:last-child,
.kit-dash.kit-dash-constrained .kit-dash-constrainer .kit-dash-segment-container > .ui.vertical.segment:last-child {
	border-bottom-right-radius: 0.28571429rem;
}
.kit-dash-constrainer.kit-dash-segment-container.kit-dash-scroll-container > .ui.vertical.segment:last-child,
.kit-dash-constrainer .kit-dash-segment-container.kit-dash-scroll-container > .ui.vertical.segment:last-child,
.kit-dash-constrainer.kit-dash-segment-container.kit-dash-scroll-content > .ui.vertical.segment:last-child,
.kit-dash-constrainer .kit-dash-segment-container.kit-dash-scroll-content > .ui.vertical.segment:last-child {
	border-bottom-right-radius: 0;
	border-bottom: none;
}
.kit-nav-container.should-animate + .kit-dash-container {
	transition: max-width .2s ease;
}
.kit-nav-container.visible + .kit-dash-container {
	max-width: calc(100% - 250px);
}
.kit-dash-container .ui.right.rail {
	padding: 0;
	margin: 0 0 0 1rem;
}
.kit-dash-container .ui.right.rail .ui.sticky,
.kit-dash-container .ui.right.rail .kit-dash-scroll-content {
	padding: 2rem 1rem 1rem 1rem;
}
.kit-dash-rail-observer {
	position: fixed;
	top: 0;
	height: 100%;
	max-height: 100vh;
	pointer-events: none;
	visibility: hidden;
}
.kit-dash-breadcrumb-segment + .kit-dash-content .kit-dash-rail-observer {
	top: 48px;
	max-height: calc(100vh - 48px);
}
.kit-dash-scroll-container {
	display: flex;
	height: 100%;
	flex-direction: column;
	background: #fff;
}
.kit-dash-scroll-content {
	flex: 0 1 auto;
	height: 100%;
	overflow-y: auto;
	overflow-x: visible;
	position: relative;
}
.kit-dash-segment-container.kit-dash-scroll-content {
	border-right: 1px solid rgba(34,36,38,.15);
}
.kit-dash-content:not(.kit-dash-content-fullheight) .kit-dash-scroll-content {
	border-bottom: 1px solid rgba(34,36,38,.15);
	border-bottom-right-radius: 0.28571429rem;
}
.ui.popup.kit-usermenu-popup {
	max-width: 320px;
}
.kit-usermenu-popup .ui.header,
.kit-usermenu-popup p {
	margin-bottom: 0;
}
.kit-usermenu-company-name,
.kit-usermenu-name,
.kit-usermenu-email {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	display: inline-block;
}

/* Field segment */
.ui.field.segment {
	padding: 0;
	margin: 0 -1px;
	border: none;
	position: relative;
}
.ui.field.segment > .ui.section.header {
	pointer-events: none;
	position: absolute;
	top: 1.5rem;
	left: 1.5rem;
	z-index: 2;
}
.ui.field.segment > .ui.input > input {
	padding: 1.5rem;
	line-height: 1.4em;
}
.ui.field.segment:not(.error) > .ui.input > input:not(:focus) {
	border-color: #D4D4D5;
}
.ui.attached.field.segment.error > .ui.input > input,
.ui.attached.field.segment > .ui.input > input:focus {
	z-index: 1;
}
.ui.field.segment.with-header > .ui.input > input {
	padding-top: 3rem;
}
.ui:not(.top).attached.field.segment > .ui.input > input {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.ui:not(.bottom).attached.field.segment > .ui.input > input {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.ui.attached.segment + .ui.attached.field.segment:not(.top) {
	margin-top: -1px;
}

/* Z-index fix for messages attached on top of field segments */
.ui.attached.message {
	z-index: 1;
}

/* Border fix for attached colored segments */
.ui.inverted.attached.red.segment,
.ui.inverted.attached.orange.segment,
.ui.inverted.attached.yellow.segment,
.ui.inverted.attached.olive.segment,
.ui.inverted.attached.green.segment,
.ui.inverted.attached.teal.segment,
.ui.inverted.attached.blue.segment,
.ui.inverted.attached.violet.segment,
.ui.inverted.attached.purple.segment,
.ui.inverted.attached.pink.segment,
.ui.inverted.attached.brown.segment,
.ui.inverted.attached.grey.segment {
	border-color: transparent;
}

/* Field header */
.ui.field.header {
	margin-bottom: .28571429rem;
}

/* Very basic label */
.ui.basic.label[class*="very basic"] {
	background: transparent!important;
	border: none;
	padding: 0;
}

/* No margin on header labels */
.ui.header > .ui.label:last-child {
	margin-left: 0;
}

/* Label right icon */
.ui.label > .icon.right {
	margin-right: 0;
	margin-left: .5rem;
}

/* Placeholder fixes */
.ui.secondary.segment > .ui.placeholder,
.ui.secondary.segment > .ui.placeholder > ::before,
.ui.secondary.segment > .ui.placeholder .image.header::after,
.ui.secondary.segment > .ui.placeholder .line,
.ui.secondary.segment > .ui.placeholder .line::after,
.ui.secondary.segment > .ui.container > .ui.placeholder,
.ui.secondary.segment > .ui.container > .ui.placeholder > ::before,
.ui.secondary.segment > .ui.container > .ui.placeholder .image.header::after,
.ui.secondary.segment > .ui.container > .ui.placeholder .line,
.ui.secondary.segment > .ui.container > .ui.placeholder .line::after,
.ui.secondary.segment > .ui.container > .ui.breadcrumb > .section > .ui.placeholder,
.ui.secondary.segment > .ui.container > .ui.breadcrumb > .section > .ui.placeholder > ::before,
.ui.secondary.segment > .ui.container > .ui.breadcrumb > .section > .ui.placeholder .image.header::after,
.ui.secondary.segment > .ui.container > .ui.breadcrumb > .section > .ui.placeholder .line,
.ui.secondary.segment > .ui.container > .ui.breadcrumb > .section > .ui.placeholder .line::after {
	background-color: #F3F4F5;
}
.ui.card .ui.placeholder,
.ui.card .ui.placeholder > ::before,
.ui.card .ui.placeholder .image.header::after,
.ui.card .ui.placeholder .line,
.ui.card .ui.placeholder .line::after,
.ui.segment:not(.inverted):not(.disabled):not(.loading):not(.secondary):not(.tertiary) .ui.placeholder,
.ui.segment:not(.inverted):not(.disabled):not(.loading):not(.secondary):not(.tertiary) .ui.placeholder > ::before,
.ui.segment:not(.inverted):not(.disabled):not(.loading):not(.secondary):not(.tertiary) .ui.placeholder .image.header::after,
.ui.segment:not(.inverted):not(.disabled):not(.loading):not(.secondary):not(.tertiary) .ui.placeholder .line,
.ui.segment:not(.inverted):not(.disabled):not(.loading):not(.secondary):not(.tertiary) .ui.placeholder .line::after {
	background-color: #FFFFFF;
}
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.striped.table tr:nth-child(2n) .ui.placeholder,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.striped.table tr:nth-child(2n) .ui.placeholder > ::before,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.striped.table tr:nth-child(2n) .ui.placeholder .image.header::after,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.striped.table tr:nth-child(2n) .ui.placeholder .line,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.striped.table tr:nth-child(2n) .ui.placeholder .line::after,
.ui.striped.table tr:nth-child(2n) .ui.placeholder,
.ui.striped.table tr:nth-child(2n) .ui.placeholder > ::before,
.ui.striped.table tr:nth-child(2n) .ui.placeholder .image.header::after,
.ui.striped.table tr:nth-child(2n) .ui.placeholder .line,
.ui.striped.table tr:nth-child(2n) .ui.placeholder .line::after {
	background-color: #FAFAFB;
}
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tr.active .ui.placeholder,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tr.active .ui.placeholder > ::before,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tr.active .ui.placeholder .image.header::after,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tr.active .ui.placeholder .line,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tr.active .ui.placeholder .line::after,
.ui.table tr.active .ui.placeholder,
.ui.table tr.active .ui.placeholder > ::before,
.ui.table tr.active .ui.placeholder .image.header::after,
.ui.table tr.active .ui.placeholder .line,
.ui.table tr.active .ui.placeholder .line::after {
	background-color: #E0E0E0;
}
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table td.active .ui.placeholder,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table td.active .ui.placeholder > ::before,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table td.active .ui.placeholder .image.header::after,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table td.active .ui.placeholder .line,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table td.active .ui.placeholder .line::after,
.ui.table td.active .ui.placeholder,
.ui.table td.active .ui.placeholder > ::before,
.ui.table td.active .ui.placeholder .image.header::after,
.ui.table td.active .ui.placeholder .line,
.ui.table td.active .ui.placeholder .line::after {
	background-color: #E0E0E0;
}
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.selectable.table tbody tr:hover .ui.placeholder,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.selectable.table tbody tr:hover .ui.placeholder > ::before,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.selectable.table tbody tr:hover .ui.placeholder .image.header::after,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.selectable.table tbody tr:hover .ui.placeholder .line,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.selectable.table tbody tr:hover .ui.placeholder .line::after,
.ui.selectable.table tbody tr:hover .ui.placeholder,
.ui.selectable.table tbody tr:hover .ui.placeholder > ::before,
.ui.selectable.table tbody tr:hover .ui.placeholder .image.header::after,
.ui.selectable.table tbody tr:hover .ui.placeholder .line,
.ui.selectable.table tbody tr:hover .ui.placeholder .line::after {
	background-color: #F2F2F2;
}
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tbody tr td.selectable:hover .ui.placeholder,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tbody tr td.selectable:hover .ui.placeholder > ::before,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tbody tr td.selectable:hover .ui.placeholder .image.header::after,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tbody tr td.selectable:hover .ui.placeholder .line,
.ui.segment:not(.inverted):not(.disabled):not(.loading) .ui.table tbody tr td.selectable:hover .ui.placeholder .line::after,
.ui.table tbody tr td.selectable:hover .ui.placeholder,
.ui.table tbody tr td.selectable:hover .ui.placeholder > ::before,
.ui.table tbody tr td.selectable:hover .ui.placeholder .image.header::after,
.ui.table tbody tr td.selectable:hover .ui.placeholder .line,
.ui.table tbody tr td.selectable:hover .ui.placeholder .line::after {
	background-color: #F2F2F2;
}

.ui.breadcrumb .section .ui.placeholder {
	margin-top: -.5em;
}

/* Breadcrumb icon margin fix */
.ui.breadcrumb i.icon:not(.divider),
.ui.breadcrumb i.icons:not(.divider) {
	margin: 0em 0.35714286em 0em 0em;
}

/* Table button fix */
.ui.table td .ui.button:last-child {
	margin-right: 0;
}

/* Custom styles: Cart table */
.kit-cart-table thead tr th:last-child {
	width: 1%;
	text-align: right;
}
.kit-cart-table tbody tr td:last-child {
	width: 1%;
	text-align: right;
}
.kit-cart-table tfoot tr:nth-last-child(2) th {
	font-weight: bold;
}
.kit-cart-table tfoot tr:last-child th {
	border-top: none;
	padding-top: 0;
}

/* Custom styles: Option table */
.kit-option-table-checkbox-cell {
	width: 1%;
}
.kit-option-table-checkbox-cell .ui.checkbox {
	min-width: 17px;
	min-height: 17px;
	margin-left: 8px;
}
.right.aligned.kit-option-table-checkbox-cell .ui.checkbox {
	margin-left: 0;
	margin-right: 8px;
}
.kit-option-table-checkbox-cell .ui.checkbox input[type="checkbox"],
.kit-option-table-checkbox-cell .ui.checkbox input[type="radio"] {
	width: 19px;
	height: 19px;
}

/* Custom styles: Log table */
.kit-log-table pre {
	line-break: anywhere;
    word-break: break-all;
    white-space: pre-wrap;
}

/* Custom styles: Option grid */
/* .ui.grid.kit-option-grid {
	margin-top: -2rem;
	margin-bottom: 0rem;
} */
.ui.grid.kit-option-grid > .row > .column {
	/* margin-top: 1rem; */
	margin-bottom: 1rem;
}

/* Step fix */
.ui.steps .step > .icon ~ .content {
	flex-basis: calc(100% - 2.95em - 1rem);
}
.ui.steps .step > .icon {
	flex-basis: 1.18em;
}

/* Left pointing step */
.ui.vertical.steps .step[class*="left pointing"]::after {
	right: 100%;
	border-width: 1px 0px 0px 1px;
}

/* Input middle label fix */
.ui.labeled.input > .label:not(:first-child):not(:last-child) {
	border-radius: 0;
}

/* Make default label and button background transparent */
.ui.label:not(.pointing):not(.ribbon),
.ui.button {
	background-color: rgba(153, 153, 153, 0.25);
}

/* Icon label fix */
.ui.label > .icon:not(.left):last-child {
	margin-right: 0;
}

/* Custom product button */
.kit-product-button {
	text-align: left!important;
	padding: 0 1px!important;
	display: flex!important;
	flex-direction: column;
}
.kit-product-button :first-child {
	flex: 1 0 auto;
}
.kit-product-button .ui.segment {
	transition: background .2s ease;
	border: none;
}
.kit-product-button:hover .ui.segment,
.kit-product-button:focus .ui.segment,
.kit-product-button.active .ui.segment {
	background: #fff3eb;
}
.ui.active.button.kit-product-button {
	box-shadow: 0 0 0 2px #F2711C;
}

/* Form field stacking fix */
.ui.form .field > .field + .field {
	margin-top: 1em;
}

/* Form field paragraph margin */
.ui.form .field > input + p,
.ui.form .field > .ui.input + p {
	margin-top: 0.28571429rem;
}

/* Clickable table rows */
.ui.clickable.table tbody tr:hover,
.ui.table tbody tr.clickable:hover {
	cursor: pointer;
}

/* Custom page info section */
.kit-page-info-text {
	display: inline-block;
	padding: 0.78571429em;
	line-height: 1em;
}

/* Segment/card color fix */
.ui.segment:not(.inverted):not(.secondary):not(.tertiary):not(.disabled):not(.loading) {
	color: rgba(0, 0, 0, .87);
}
.ui.card {
	color: rgba(0, 0, 0, .87);
}

/* ResourceCard styling */
.ui.card > .kit-resource-card-label-content + .content {
	border-top: none!important;
}
.ui.cards > .card > .kit-resource-card-label-content,
.ui.card > .kit-resource-card-label-content {
	flex-grow: 0;
}

/* DomainContact styling */
.kit-domain-contact-card {
	line-break: anywhere;
}
.kit-domain-contact-card .content .ui.header + .ui.header {
	margin-top: 0;
}

/* Label width fix */
.ui.image.label {
	display: inline-flex;
}
.ui.image.label > .content {
	flex: 1 0 auto;
}
.ui[class*="top attached"].image.label,
.ui.attached.image.label {
	width: 100%!important;
}
.ui[class*="top attached"].label .detail,
.ui.attached.label .detail {
    margin: -0.75em -1em;
    padding: 0.75em 1em;
}

/* Attached label + grid margin fix */
.ui.top.attached.label:first-child + .ui.grid {
	margin-top: 1rem!important;
}

/* Statistic "Å" fix */
.ui.statistics .statistic > .label ~ .value.needs-space-above,
.ui.statistic > .label ~ .value.needs-space-above {
	margin-top: 1rem;
}

/* Disabled message styling */
.ui.disabled.message {
	pointer-events: none;
	user-select: none;
	opacity: .45;
}

/* Message word-break fix */
.ui.message {
	word-wrap: break-word;
	word-break: break-word;
	white-space: normal;
}
.ui.message .content pre {
	overflow: auto;
}

/* Icon message redesign */
.ui.icon:not(.small-icon).message {
	padding: 1.5em;
}
.ui.icon.small-icon.message > .icon:not(.close) {
	font-size: 1em;
}
.ui.icon:not(.small-icon).message > .icon:not(.close) {
	vertical-align: baseline;
	align-self: flex-start;
	font-size: 1.5em;
}
.ui.icon.message > .icon:not(.close) {
	height: auto;
	margin-right: .8em;
	border-radius: 500rem;
	opacity: 1;
}

/* Konsept-IT Asset label */
.kit-assetlabel {
	position: relative;
	background: #fff;
	color: #000;
	font-family: Arial, sans-serif;
	width: 31em;
	height: calc(calc(31 / 70) * 40);
}
.kit-assetlabel * { line-height: 1.1em; }
.kit-assetlabel p { margin: 0; margin-bottom: .5em; }
.kit-assetlabel-inner {
	position: relative;
	padding: 2em;
}
.kit-assetlabel-column input {
	display: inline-block;
	border: none;
	padding: 0;
	width: 100%;
}
.kit-assetlabel-column input.kit-assetlabel-text-primary {
	width: 100%;
}
.kit-assetlabel-column *:not(input):not([contenteditable]) {
	user-select: none;
	pointer-events: none;
}
.kit-assetlabel-text-primary {
	font-size: 2em;
	margin-bottom: .5em;
}
.kit-assetlabel-row {
	display: flex;
	align-items: stretch;
}
.kit-assetlabel-row > .kit-assetlabel-column {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
}
.kit-assetlabel-text-secondary {
	font-size: 1.2em;
}
.kit-assetlabel-logo {
	color: #000;
	display: inline-block;
	height: 1em;
	margin-top: -.25em;
	vertical-align: middle;
}
.kit-assetlabel-device-type {
	position: absolute;
	top: 0;
	left: 0;
}
.kit-assetlabel-row-name {
	height: 30%;
}
.kit-assetlabel-row-content {
	height: 70%;
}
.kit-assetlabel-row > .kit-assetlabel-column-contactinfo {
	width: 65%;
}
.kit-assetlabel-row > .kit-assetlabel-column-qrcode {
	width: 35%;
}

/* Custom component: Slider */
.ui.slider {
    padding-top: 1em;
    padding-bottom: 1em;
}

.ui.slider:active > .ui.progress,
.ui.slider:hover > .ui.progress {
    background: rgba(0,0,0,.15);
}

.ui.slider:active > .ui.inverted.progress,
.ui.slider:hover > .ui.inverted.progress {
  background: rgba(255,255,255,.15);
}

.ui.slider > .ui.progress {
    margin: 0;
    width: 100%;
    transition: background .2s ease;
}

.ui.slider > .ui.progress > .bar {
	min-width: 0;
	transition: none;
}

.ui.slider > .ui.progress > .bar::after {
	content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: .5em;
    height: .5em;
    border-radius: 50%;
    z-index: 1;
}

.ui.slider:hover .ui.progress > .bar::after,
.ui.slider.grabbed .ui.progress > .bar::after {
    box-shadow: 0 0 0 .25em #aaa;
    background: #aaa;
}

.ui.slider:active .ui.progress > .bar::after,
.ui.slider.grabbed .ui.progress > .bar::after {
    background: #888;
}

/* Custom datatable styling */
.ui.table > thead > tr > th > button:not(.ui.button) {
	appearance: none;
	-webkit-appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: inherit;
}

.kit-datatable-column-single-line-no-overflow {
	max-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ui.form.kit-datatable-controls input:not([type]),
.ui.form.kit-datatable-controls input[type="date"],
.ui.form.kit-datatable-controls input[type="datetime-local"],
.ui.form.kit-datatable-controls input[type="email"],
.ui.form.kit-datatable-controls input[type="number"],
.ui.form.kit-datatable-controls input[type="password"],
.ui.form.kit-datatable-controls input[type="search"],
.ui.form.kit-datatable-controls input[type="tel"],
.ui.form.kit-datatable-controls input[type="time"],
.ui.form.kit-datatable-controls input[type="text"],
.ui.form.kit-datatable-controls input[type="file"],
.ui.form.kit-datatable-controls input[type="url"] {
	padding: .60714286em 1em;
}

/* Custom DNS datatable/form styling */
.kit-dns-edit-form .fields:last-child {
	margin-bottom: 0;
}
.kit-datatable-dns > tbody > tr > td > *:not(.kit-dns-edit-form) .ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
    margin: 0em -0.21428571em 0em 0.42857143em;
}

/* Custom PhoneInput dropdown styling */
.PhoneInput .ui.search.selection.dropdown > input.search {
	padding-left: calc(1em + 16px + 0.78571429rem);
}
.PhoneInput .ui.search.selection.dropdown.visible {
	flex: 1 0 auto;
}

/* Custom print styles */
.kit-print-only {
	display: none;
}
@media print {
	.kit-print-only {
		display: block;
	}
	.kit-assetlabel {
		position: fixed;
		top: 0; left: 0;
		width: 100%; height: 100%;
		padding: 6mm;
		min-width: 0;
	}
	.kit-assetlabel-inner {
		position: relative;
		padding: 0;
	}
	.kit-assetlabel .kit-assetlabel-text-primary { font-size: 16pt; margin-bottom: 8pt; }
	.kit-assetlabel .kit-assetlabel-text-secondary { font-size: 9pt; }
}

/* Responsive rails */
.kit-nav-container + .kit-dash-container .ui.rail,
.kit-nav-container + .kit-dash-container .kit-dash-rail-observer,
.kit-nav-container + .kit-dash-container .ui.rail .ui.grid > .row > .column,
.kit-nav-container + .kit-dash-container .ui.rail .ui.grid > .column:not(.row) {
	width: 300px;
}

@media only screen and (min-width: 1600px) {
	.kit-nav-container:not(.visible) + .kit-dash-container .ui.rail,
	.kit-nav-container:not(.visible) + .kit-dash-container .kit-dash-rail-observer {
		width: 600px;
	}
}

@media only screen and (min-width: 1850px) {
	.kit-nav-container.visible + .kit-dash-container .ui.rail,
	.kit-nav-container.visible + .kit-dash-container .kit-dash-rail-observer {
		width: 600px;
	}

	.kit-nav-container:not(.visible) + .kit-dash-container .ui.rail,
	.kit-nav-container:not(.visible) + .kit-dash-container .kit-dash-rail-observer {
		width: 850px;
	}
	.kit-nav-container:not(.visible) + .kit-dash-container .ui.rail .ui.grid > .row > .column,
	.kit-nav-container:not(.visible) + .kit-dash-container .ui.rail .ui.grid > .column:not(.row) {
		width: 425px;
	}
}

@media only screen and (min-width: 2100px) {
	.kit-nav-container.visible + .kit-dash-container .ui.rail,
	.kit-nav-container.visible + .kit-dash-container .kit-dash-rail-observer {
		width: 850px;
	}
	.kit-nav-container.visible + .kit-dash-container .ui.rail .ui.grid > .row > .column,
	.kit-nav-container.visible + .kit-dash-container .ui.rail .ui.grid > .column:not(.row) {
		width: 425px;
	}
}

/* Responsive containers and cards */
@media only screen and (max-width: 767px) {
	.ui.container,
	.ui.grid.container,
	.ui.relaxed.grid.container,
	.ui.very.relaxed.grid.container {
		width: 100%!important;
		margin: 0!important;
	}
	.kit-dash-segment-container > .ui.vertical.segment:not(.kit-dash-breadcrumb-segment),
	.kit-dash-segment-container > .ui.padded.segment.vertical.segment:not(.kit-dash-breadcrumb-segment),
	.kit-dash-segment-container > .ui[class*="very padded"].vertical.segment:not(.kit-dash-breadcrumb-segment) {
		padding-left: 1em;
		padding-right: 1em;
	}
	.ui.stackable.cards {
		margin: 0 -1em;
	}
	.ui.stackable.cards > .card {
		width: calc( 100% - 4em ) !important;
	}
	.ui.form .fields + .fields {
		margin-top: 1em;
	}
}